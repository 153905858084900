/**
 * MHotline
 *
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

import ALink from '../01-atoms/a-link.js'

import { companyInfo } from '../../../site-config'

const styles = theme => ({
  container: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '40px',
    },
  },
  hotlineHeadline: {
    fontSize: '40px',
    fontWeight: '500',
    lineHeight: '44px',
    marginBottom: '16px',
  },
  hotlineContent: {
    fontSize: '22px',
    lineHeight: '27px',
  },
  hotlineContact: {
    color: theme.palette.primary.main,
  },
})

class MHotline extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Typography
          className={classes.hotlineHeadline}
          variant="h2"
          align="center"
        >
          Noch Fragen?
        </Typography>
        <Typography
          className={classes.hotlineContent}
          component="p"
          paragraph
          align="center"
        >
          Unser Service-Team hilft Ihnen <br /> gerne weiter.
        </Typography>
        <Typography
          className={classes.hotlineContent}
          component="p"
          paragraph
          align="center"
        >
          <ALink className={classes.contact} to={`tel:${companyInfo.phone}`}>
            {companyInfo.phone}
          </ALink>
          <ALink className={classes.contact} to={`mailto:${companyInfo.email}`}>
            {companyInfo.email}
          </ALink>
        </Typography>
      </div>
    )
  }
}

MHotline.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
}

export default withStyles(styles)(MHotline)
