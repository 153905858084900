import React from 'react'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { registerRequest } from '../../actions'

import { intlIdToString } from '../00-utils/u-translation'
import AInput from '../01-atoms/a-input'
import AForm from '../01-atoms/a-form'

import Packages from '../03-organisms/o-packages'
import { companyInfo, globalNaming } from '../../../site-config'

const styles = theme => ({
  checkboxErrorState: {
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: theme.typography.pxToRem(1),
  },
  textErrorState: {
    color: theme.palette.error.main,
  },
  infoText: {
    marginTop: theme.typography.pxToRem(10),
    display: 'block',
  },
  styleGdpr: {
    paddingTop: theme.typography.pxToRem(20),
    paddingBottom: theme.typography.pxToRem(20),
    alignItems: 'flex-start',
    '& span': {
      color: theme.customStyles.extraColors.lightGrey,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  choosePackage: {
    color: theme.customStyles.extraColors.lightGrey,
    fontSize: theme.typography.pxToRem(25),
    fontWeight: '600',
    lineHeight: theme.typography.pxToRem(33),
    textAlign: 'center',
    marginBottom: theme.typography.pxToRem(5),
  },
  required: {
    color: theme.customStyles.extraColors.lightGrey,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 20,
  },
})

class ORegisterForm extends AForm {
  constructor(props) {
    super(props)
    // This binding is necessary to make `this` work in the callback
    this.addPackageType = this.addPackageType.bind(this)
  }

  afterSubmit() {
    this.props.registerRequest({
      username: this.state.fields.emailValue.value,
      company: this.state.fields.companyValue.value,
      package_type: this.state.fields.packageType.value,
    })
  }

  addPackageType(type) {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        packageType: {
          value: type,
        },
      },
    }))
  }

  componentDidMount() {
    if (this.props.data.register.registerData !== undefined) {
      this.setState(prevState => ({
        fields: {
          ...prevState.fields,
          emailValue: {
            error: false,
            value: this.props.data.register.registerData.username,
          },
          companyValue: {
            error: false,
            value: this.props.data.register.registerData.company,
          },
        },
      }))
    }
  }

  render() {
    const { classes } = this.props

    if (this.props.data.register.registered)
      return (
        <Grid container spacing={5} justify="center">
          <Grid item xs={12} sm={10} md={8}>
            <Typography component="p" className={classes.paragraph}>
              Vielen Dank für die Registrierung im {globalNaming.entryType}
              verzeichnis der {companyInfo.longName}.
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              Profitieren Sie ab sofort von der Reichweite der{' '}
              {companyInfo.longName} und nehmen Sie Kontakt mit tausenden von
              Besuchern des {globalNaming.entryType}verzeichnisses auf. Stellen
              Sie Ihre Services und Produkte in einem einzigartigen Umfeld vor
              und präsentieren Sie Ihre Best Practices und Referenzen.
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              Ihre Registrierung war erfolgreich. Sie erhalten eine E-Mail, um
              die Registrierung zu bestätigen und ihr Passwort zu setzen.
            </Typography>
          </Grid>
        </Grid>
      )

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={5} justify="center">
          <Grid item xs={12} sm={5} md={4}>
            <AInput
              error={this.fieldHasError('emailValue')}
              label={intlIdToString('email')}
              name="emailValue"
              onChange={this.handleChange.bind(this, ['isValidMail'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="email"
              value={
                this.state.fields.emailValue !== undefined
                  ? this.state.fields.emailValue.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <AInput
              error={this.fieldHasError('companyValue')}
              label={intlIdToString('company')}
              name="companyValue"
              onChange={this.handleChange.bind(this, ['isValidCompanyName'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="text"
              value={
                this.state.fields.companyValue !== undefined
                  ? this.state.fields.companyValue.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={10} md={8}>
            <FormControlLabel
              className={classes.styleGdpr}
              control={
                <Checkbox
                  error={
                    this.fieldHasError('gdprCheck')
                      ? this.fieldHasError('gdprCheck')
                      : ''
                  }
                  className={
                    this.fieldHasError('gdprCheck')
                      ? classes.checkboxErrorState
                      : ''
                  }
                  name="gdprCheck"
                  inputProps={{
                    required: true,
                  }}
                  checked={
                    this.state.fields.gdprCheck !== undefined
                      ? this.state.fields.gdprCheck.value
                      : false
                  }
                  value={this.checked}
                  onChange={this.handleChange.bind(this, ['isChecked'])}
                />
              }
              label={
                <span className={classes.infoText}>
                  Ich habe die{' '}
                  <a
                    href="https://www.ebnermedia.de/ebvm/dse"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Datenschutzbestimmungen
                  </a>{' '}
                  gelesen und akzeptiere diese.*
                </span>
              }
              classes={{
                label: this.fieldHasError('gdprCheck')
                  ? classes.textErrorState
                  : '',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.choosePackage} variant="h2">
              Wählen Sie ihr Paket:
            </Typography>
          </Grid>
        </Grid>

        <Packages buttonType="submit" addPackageType={this.addPackageType} />
        <Grid container spacing={5}>
          <Grid item xs={12} className={classes.required}>
            *Pflichtfelder
          </Grid>
        </Grid>
      </form>
    )
  }
}

ORegisterForm.propTypes = {
  data: PropTypes.object.isRequired,
  registerRequest: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    data: {
      register: state.register,
      currentlySending: state.currentlySending,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    registerRequest: data => dispatch(registerRequest(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ORegisterForm))
