import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'
import PropTypes from 'prop-types'

import APaper from '../components/01-atoms/a-paper'
import MIconHeadlineTextLine from '../components/02-molecules/m-icon-headline-text-line'
import MHotline from '../components/02-molecules/m-hotline'
import ORegisterForm from '../components/03-organisms/o-register-form.js'
import SimpleTemplateWrapper from '../components/04-templates/t-simple-template-wrapper'

import { packagesText, globalNaming } from '../../site-config'

const styles = theme => ({
  container: {
    marginBottom: theme.typography.pxToRem(20),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.typography.pxToRem(30),
    },
  },
})
class RegisterPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return (
      <SimpleTemplateWrapper showHeadlineBar headline="Firma hinzufügen">
        <APaper>
          <Grid container spacing={5} justify="center">
            <Grid item xs={12} sm={10} md={8}>
              <MIconHeadlineTextLine className={classes.container}>
                <Typography variant="h2">
                  Jetzt registrieren und von den Vorteilen des{' '}
                  {globalNaming.entryType}verzeichnisses profitieren.
                </Typography>
                <Typography component="p">{packagesText.teaser}</Typography>
              </MIconHeadlineTextLine>
            </Grid>
          </Grid>
          <ORegisterForm />
        </APaper>
        <MHotline />
      </SimpleTemplateWrapper>
    )
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegisterPage)
